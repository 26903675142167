import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const options = {
  order: ["querystring", "cookie", "localStorage", "navigator"],
  lookupCookie: "i18next",
  caches: ["localStorage"],
  lookupQuerystring: "locale"
};
const ns = ["login"];
const initI18n = () =>
  i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(Backend)
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: "en-US",
      debug: true,
      ns: ns,
      defaultNS: ns[0],
      detection: options,
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      backend: {
        loadPath: "/static/data/locales/content/{{ns}}/{{lng}}.json"
      },
      react: {
        useSuspense: true
      }
    });

export default initI18n;
