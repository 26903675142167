export function localePrefix(link: string, locale = "") {
  let url;

  try {
    url = new URL(link);
  }
  catch (err) {
    return link;
  }

  //  look for /{LOCALE_PREFIX}/ string
  const match = url.pathname.match(/^\/(%7BLOCALE_PREFIX%7D)+(.*)$/);

  if (match) {
    //
    //  Normalize Locale based on Drupal Routing Rules
    //
    let normalizedLocale = locale.replace(/-/, "_");

    //  Drupal does not support locales without territory - except for jp
    if (
      !~["jp"].indexOf(normalizedLocale) &&
      normalizedLocale.match(/^[a-z]{2}$/)
    ) {
      normalizedLocale = `${normalizedLocale}_${normalizedLocale}`;
    }

    //  www.tesla.cn should not have zh-CN prefix
    if (url.hostname.endsWith(".cn") && ~["zh-CN"].indexOf(locale)) {
      normalizedLocale = "";
    }

    //  www.tesla.com should not have en-US prefix
    if (url.hostname.endsWith(".com") && ~["en-US"].indexOf(locale)) {
      normalizedLocale = "";
    }

    //  Prepend the locale
    url.pathname = normalizedLocale
      ? `/${normalizedLocale}${match[2]}`
      : match[2];

    //  Add ?redirect=no query parameter
    url.searchParams.set("redirect", "no");
  }

  return url.toString();
}



export function normalizeLocale(locale: string) {
  return locale?.toLowerCase().replace(/-/, "_");
}
