import React from "react";
import { SiteFooter, SiteFooterLink } from "@tesla/design-system-react";
import { localePrefix } from "../../utils/locale-prefix";
import { Trans } from "react-i18next";

const Footer: React.FC<{ envConfigs: any }>= ({ envConfigs }) => {
  let locale;
  try {
    locale = window?.localStorage?.i18nextLng;
  }
  catch (err) {
    if (err instanceof Error) {
      // window.localStorage read can cause error on some browsers when cookies disabled
      console.log(err.toString());
    }
  }
  return (
    <SiteFooter align="center" className="tds-shell-footer" id="footer">
      <SiteFooterLink
        href={localePrefix(envConfigs.APP_TESLA_ABOUT_URL, locale)}
      >
        Tesla &copy; {new Date().getFullYear()}
      </SiteFooterLink>
      <SiteFooterLink
        href={localePrefix(envConfigs.APP_TESLA_PRIVACY_POLICY_URL, locale)}
      >
        <Trans i18nKey="footerPrivacyLinkLabel"/>
      </SiteFooterLink>
      <SiteFooterLink
        href={localePrefix(envConfigs.APP_TESLA_CONTACT_URL, locale)}
      >
        <Trans i18nKey="footerContactLinkLabel"/>
      </SiteFooterLink>
    </SiteFooter>
  );
};

export default Footer;
