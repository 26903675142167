import React, { useState, useRef } from "react";
import { Button, Link, StatusMessage, ToastContainer, useToastContainerState } from "@tesla/design-system-react";
import { Trans, useTranslation } from "react-i18next";
import "../../css/login.css";
import { useDispatch } from "react-redux";
import { setShowSpinner } from "../../store/reducers/layout";
import OTPForm from "../common/otp-form";
import { useLocation } from "react-router-dom";
import { normalizeLocale, localePrefix } from "../../utils/locale-prefix";

const OTPView: React.FC<{ setStep: (step: string) => void, options: any, referenceId: string, selectedOption: string, maskedSelectedData: string | undefined, envConfigs: any }> = ({ setStep, options, referenceId, selectedOption, maskedSelectedData, envConfigs }) => {
  const [otpValue, setOtpValue] = useState(["", "", "", "", "", ""]);
  const [otpDisabled, setOtpDisabled] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const { toasts, addToast } = useToastContainerState();
  const [errors, setErrors] = useState<Record<string, any>>({});
  const inputRef = useRef<HTMLInputElement[]>([]);
  const OTP_LENGTH = 6;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const locale = urlParams.get("locale") || "";

  const clearOTP = () => {
    const initialValue = new Array(OTP_LENGTH).fill("");
    setOtpValue(initialValue);
  };

  const submitOtp = async (code: string) => {
    dispatch(setShowSpinner(true));
    setErrors({});
    const result = await fetch(`${locale ? `/${normalizeLocale(locale)}` : ""}/api/v1/auth/2fa/verify-code`, {
      method: "POST",
      body: JSON.stringify({
        referenceId: referenceId,
        login_method: selectedOption,
        code: code
      }),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(async (res) => {
      const response = await res.json();
      if (response?.error?.message) {
        if (response?.error?.message === "Invalid OTP") {
          clearOTP();
          inputRef.current?.[0]?.focus();
          setErrors({ otp: t("invalidOTP") });
        }
        else if (response?.error.message === "You have reached the limit of code verification") {
          setOtpDisabled(true);
          setErrors({ status: t("verifyLimitReached") });
          setResendDisabled(true);
        }
        else {
          setErrors({ status: t("somethingWentWrong") });
        }
      }
      return response;
    }).catch((err) => {
      console.log("ERROR:", err);
    });
    if (result?.data?.redirect_uri) {
      window.location.replace(`${envConfigs?.APP_HOST || ""}${result?.data?.redirect_uri}`);
      return;
    }
    dispatch(setShowSpinner(false));
  };

  const resendCode = async () => {
    dispatch(setShowSpinner(true));
    await fetch(`${locale ? `/${normalizeLocale(locale)}` : ""}/api/v1/auth/2fa/send-code`, {
      method: "POST",
      body: JSON.stringify({
        referenceId: referenceId,
        login_method: selectedOption
      }),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(async (res) => {
      const response = await res.json();
      if (response?.error?.message) {
        switch (response.error.message!) {
          case "You have reached the limit":
            setErrors({ status: t("resendLimitReached") });
            setResendDisabled(true);
            break;
          case "You have reached the limit of code verification":
            setOtpDisabled(true);
            setErrors({ status: t("verifyLimitReached") });
            setResendDisabled(true);
            break;
          case "Invalid phone number":
            setOtpDisabled(true);
            setErrors({ status: t("invalidPhoneNumber") });
            setResendDisabled(true);
            break;
          case "This number is landline and cannot receive SMS":
            setOtpDisabled(true);
            setErrors({ status: t("landLineNumber") });
            setResendDisabled(true);
            break;
          case "This number is blocked and cannot receive SMS":
            setOtpDisabled(true);
            setErrors({ status: t("blockedNumber") });
            setResendDisabled(true);
            break;

          default:
            setErrors({ status: t("somethingWentWrong") });
        }
      }
      if (response?.data?.success) {
        addToast({
          title: t("codeResent"),
          variant: "status",
          statusType: "success",
          dismissible: true,
          autoTimeoutMs: 3000
        });
      }
      return response;
    }).catch((err) => {
      console.log("ERROR:", err);
    });
    dispatch(setShowSpinner(false));
  };

  return (
    <>
      <ToastContainer toasts={toasts} />
      <div className="single-column-form-wrapper">
        <h1 className="tds-text--h1-alt">
          <Trans i18nKey="enterCode" defaults="Enter Code" />
        </h1>
        {errors.status && <StatusMessage
          body={
            <div
              dangerouslySetInnerHTML={{
                __html: t(`${errors.status}`, {
                  linkStart:
                    `<a class='tds-link' href=${localePrefix(envConfigs.APP_TESLA_ACCOUNT_SETTINGS_URL, locale)}>`,
                  linkEnd: "</a>",
                  interpolation: { escapeValue: false }
                })
              }}
            />
          }
          className=""
          enclosed
          type="error"
        />}
        <p>
          <Trans i18nKey="enterCodeDescription" defaults={"Enter the code sent to {{identity}}"}
            values={{
              identity: maskedSelectedData
            }}
          />
        </p>
        <OTPForm
          errorMessage={errors.otp || ""}
          formDisabled={otpDisabled}
          inputRef={inputRef}
          otpValue={otpValue}
          setOtp={(v) => setOtpValue(v)}
          submit={submitOtp}
        />
        <Button
          variant="primary"
          width="full"
          className="next-btn"
          disabled={resendDisabled}
          onClick={() => resendCode()}
        >
          <Trans i18nKey="resendCode" defaults="Resend Code" />
        </Button>
        {options?.phone && options?.email ?
          <div className="verify-another-way-link">
            <Link
              onClick={() => {
                setStep("select-option"); setErrors({});
              }}
            >
              <Trans i18nKey="verifyAnotherWay" defaults="Verify Another Way" />
            </Link>
          </div>
          : !options?.email_verified ?
            <div className="skip-link">
              <Link
                onClick={() => {
                  dispatch(setShowSpinner(true));
                  window.location.replace(`${envConfigs?.APP_HOST || ""}${options?.redirect_uri}&skip=true`);
                  return;
                }}
              >
                <Trans i18nKey="skipAndContinue" defaults="Skip and Continue" />
              </Link>
            </div>
            : null
        }
        {options?.user_verification &&
          <div className="continue-link">
            <Link
              onClick={() => {
                dispatch(setShowSpinner(true));
                window.location.replace(`${envConfigs?.APP_HOST || ""}${options?.redirect_uri}`);
                return;
              }}
            >
              <Trans i18nKey="formContinueLabel" defaults="Continue" />
            </Link>
          </div>
        }
      </div>
    </>
  );
};

export default OTPView;
