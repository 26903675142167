import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./app";
import "@tesla/design-system/dist/index.css";
import { Provider } from "react-redux";
import store from "./store/";
import initI18n from "./utils/i18n";
import { Loader } from "@tesla/design-system-react";
import Spinner from "./components/layout/spinner";

initI18n();

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader show />}>
      <Spinner/>
      <App/>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
