import React, { useEffect, useState } from "react";
import Footer from "../layout/footer";
import Header from "../layout/header";
import Content from "../layout/content";
import "../../css/login.css";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setShowSpinner } from "../../store/reducers/layout";
import SelectOption from "./select-option";
import OTPView from "./otp-view";
import { useTranslation } from "react-i18next";
import ErrorPage from "../common/error-page";
import { normalizeLocale } from "../../utils/locale-prefix";
 
const TwoFA: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [step, setStep] = useState("select-option");
  const [envConfigs, setEnvConfigs] = useState({});
  const [errors, setErrors] = useState<Record<string, any>>({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { i18n } = useTranslation();
  const [verificationData, setVerificationData] = useState<Record<string, any>>({});
  const urlParams = new URLSearchParams(location.search);
  const referenceId = urlParams.get("reference_id") || "";
  const locale = urlParams.get("locale");
  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale);
    }
    const fetchConfigs = async() => {
      await fetch("/_assets/data/app.config.json")
        .then((res) => res.json())
        .then((json) => {
          setEnvConfigs(json);
        });
    };
    fetchConfigs();
  }, []);
  useEffect(() => {
    dispatch(setShowSpinner(true));
    const fetchOptions = async () => {
      const result = await fetch(`${locale ? `/${normalizeLocale(locale)}` : ""}/api/v1/auth/2fa/options/${referenceId}`).then((res) => res.json());
      setVerificationData(result?.data);
      if (result?.error) {
        setErrors({ status: t("somethingWentWrong") });
        dispatch(setShowSpinner(false));
        return;
      }
      if (!result?.data?.phone) {
        setSelectedOption("email");
        setStep("otp");
        await fetch("/api/v1/auth/2fa/send-code", {
          method: "POST",
          body: JSON.stringify({
            referenceId: referenceId,
            login_method: "email"
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }).then((res)=> res.json());
        
      }
      dispatch(setShowSpinner(false));
    };
    fetchOptions();
  }, [referenceId]);

  return (
    <div className="page-wrapper">
      <Header envConfigs={envConfigs}/>
      <Content contentSize="small">
        {step === "select-option" && <SelectOption selectedOption={selectedOption} setSelectedOption={setSelectedOption} setStep={setStep} options={verificationData} referenceId={referenceId} envConfigs={envConfigs} errors={errors} setErrors={setErrors}/>}
        {step === "otp" && <OTPView setStep={setStep} options={verificationData} referenceId={referenceId} selectedOption={selectedOption} maskedSelectedData={verificationData[selectedOption]} envConfigs={envConfigs}/> }
      </Content>
      <Footer envConfigs={envConfigs}/>
    </div>
  );
};

export default TwoFA;
