import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import "@tesla/design-system/dist/index.css";
import TwoFA from "./components/2fa";
import { useTranslation } from "react-i18next";

const App:React.FC = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.reloadResources();
    
  },[i18n]);

  return (
    <HashRouter hashType="slash">
      <Switch>
        <Route path="/2fa" component={TwoFA} />
      </Switch>
    </HashRouter>
  );
};

export default App;
