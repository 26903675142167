import { Loader } from "@tesla/design-system-react";
import { useSelector } from "react-redux";
import { getSpinnerState } from "../../store/reducers/layout";
import React from "react";

export const Spinner: React.FC = () => {
  const spinnerState = useSelector(getSpinnerState) || false;
  return <Loader show={spinnerState} />;
};

export default Spinner;
