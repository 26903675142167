import React from "react";

import classNames from "classnames";
const cx = classNames;

const Content: React.FC<{contentSize?: string | undefined}> = ({ children, contentSize }) => (
  <main
    className={cx("tds-shell-content")}
    style={{ maxWidth: "100vw", height: "90vh" }}
    aria-label="shell-content"
  >
    <div className={contentSize === "medium" ? "tds-content_container tds-content_container--max_width tds-content_container--medium" : "tds-content_container tds-content_container--max_width tds-content_container--small"} >
      {children}
    </div>
  </main>
);
export default Content;
