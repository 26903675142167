/** Layout related data can be maintained using this reducer  */
import { createSlice } from "@reduxjs/toolkit";

export const initialLayoutState = {
  gatedJson: [],
  showSpinner: false,
};

export const layoutSlice = createSlice({
  initialState: {
    ...initialLayoutState,
  },
  name: "layout",
  reducers: {
    setShowSpinner: (state, action) => {
      state.showSpinner = action.payload;
    },
  },
});

export const { setShowSpinner } = layoutSlice.actions;
export const getSpinnerState = (state: any) => state[layoutSlice?.name]?.showSpinner;

export default layoutSlice.reducer;

