import React, { useMemo } from "react";
import { Button, FormItem, FormInputChoiceGroup, Link, Chip, StatusMessage } from "@tesla/design-system-react";
import { Trans, useTranslation } from "react-i18next";
import "../../css/login.css";
import { useDispatch } from "react-redux";
import { setShowSpinner } from "../../store/reducers/layout";
import { useLocation } from "react-router-dom";
import { normalizeLocale, localePrefix } from "../../utils/locale-prefix";

const SelectOption: React.FC<{ setSelectedOption: (value: string) => void, options: any, setStep: (step: string) => void, selectedOption: string, referenceId: string, envConfigs: any, errors: any, setErrors: any }> = ({ setSelectedOption, options, setStep, selectedOption, referenceId, envConfigs, errors, setErrors }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const locale = urlParams.get("locale") || "";

  const formOptions = useMemo(() => {
    const formOptions: any = [];
    if (options?.phone) {
      formOptions.push({
        label: <>
          <Trans i18nKey="textToPhone" defaults="Text to {{phone}}"
            values={{
              phone: options?.phone
            }}
          />
          {options?.phone_verified && <Chip text={t("verified")} statusColor="green" density="dense" />}
        </>,
        value: "phone"
      });
    }
    if (options?.email) {
      formOptions.push({
        label: <>
          <Trans i18nKey="emailToEmail" defaults="Email to {{email}}"
            values={{
              email: options?.email
            }}
          />
          {options?.email_verified && <Chip text="Verified" statusColor="green" density="dense" />}
        </>,
        value: "email"
      });
    }
    return formOptions;
  }, [options]);

  const onNextClick = async () => {
    dispatch(setShowSpinner(true));
    await fetch(`${locale ? `/${normalizeLocale(locale)}` : ""}/api/v1/auth/2fa/send-code`, {
      method: "POST",
      body: JSON.stringify({
        referenceId: referenceId,
        login_method: selectedOption
      }),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(async (res) => {
      const response = await res.json();
      if (response?.error?.message) {
        switch (response.error.message!) {
          case "You have reached the limit":
            setErrors({ status: t("resendLimitReached") });
            break;
          case "You have reached the limit of code verification":
            setErrors({ status: t("verifyLimitReached") });
            break;
          case "Invalid phone number":
            setErrors({ status: t("invalidPhoneNumber") });
            break;
          case "This number is landline and cannot receive SMS":
            setErrors({ status: t("landLineNumber") });
            break;
          case "This number is blocked and cannot receive SMS":
            setErrors({ status: t("blockedNumber") });
            break;
          default:
            setErrors({ status: t("somethingWentWrong") });
        }
        setStep("select-option");
      }
      else {
        setErrors({});
        setStep("otp");
      }
      dispatch(setShowSpinner(false));
    }
    );
  };

  return (
    <>
      <h1 className="tds-text--h1-alt">
        <Trans i18nKey="select2FAVerifyMethodHeader" defaults="Verify Your Identity" />
      </h1>
      <p>
        <Trans i18nKey="select2FAVerifyMethodDescription" defaults="Please choose how you'd like to receive your code" />
      </p>
      {errors.status && <StatusMessage
        body={
          <div
            dangerouslySetInnerHTML={{
              __html: t(`${errors.status}`, {
                linkStart:
                  `<a class='tds-link' href=${localePrefix(envConfigs.APP_TESLA_ACCOUNT_SETTINGS_URL, locale)}>`,
                linkEnd: "</a>",
                interpolation: { escapeValue: false }
              })
            }}
          />
        }
        className=""
        enclosed
        type="error"
      />}

      <div className="single-column-form-wrapper">
        <FormItem className="select-device-form-input">
          <FormInputChoiceGroup
            options={formOptions}
            onOptionSelect={(value: any) => setSelectedOption(value)}
            selected={selectedOption}
          />
        </FormItem>
        <Button
          variant="primary"
          width="full"
          className="next-btn"
          disabled={!selectedOption}
          onClick={() => onNextClick()}
        >
          <Trans i18nKey="formNextLabel" defaults="Next" />
        </Button>
        {!options?.phone_verified && !options?.email_verified &&
          <div className="skip-link">
            <Link
              onClick={() => {
                dispatch(setShowSpinner(true));
                window.location.replace(`${envConfigs?.APP_HOST || ""}${options?.redirect_uri}&skip=true`);
                return;
              }}
            >
              <Trans i18nKey="skipAndContinue" defaults="Skip and Continue" />
            </Link>
          </div>
        }
        {options?.user_verification &&
          <div className="continue-link">
            <Link
              onClick={() => {
                dispatch(setShowSpinner(true));
                window.location.replace(`${envConfigs?.APP_HOST || ""}${options?.redirect_uri}`);
                return;
              }}
            >
              <Trans i18nKey="formContinueLabel" defaults="Continue" />
            </Link>
          </div>
        }
      </div>
    </>
  );
};

export default SelectOption;
